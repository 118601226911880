






















































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsFullTopBarLayout from '@/components/VsFullTopBarLayout/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsHeatmap from '@/modules/campaigns/components/VsHeatmap/Index.vue'
import html2pdf from 'html2pdf.js'
import {
    getListTrendSubscription,
} from '@/api/consoleApi/reports'
import VsChart from '@/components/VsChart/Index.vue'
import { formatUnixDate } from '@/utils/formatter'
import VsTable from '@/components/VsTable/Index.vue'
import { UserModule } from '@/store/modules/user'
import VsSeparator from '@/components/VsSeparator/Index.vue'
import { getListContacts } from '@/api/consoleApi/recipients'

@Component({
    name: 'VsListStatisticsFullAcquisition',
    components: {
        VsContainer,
        VsFullTopBarLayout,
        VsChart,
        VsLoader,
        VsSectionHeader,
        VsTable,
        VsHeatmap,
        VsSeparator,
    },
})
export default class extends Vue {
    @Prop({ default: null, required: true }) statistics!: any
    @Prop({ default: null, required: true }) list!: any
    private open = false
    private loading = false
    private subscriptionTime: 'last24Hours' | 'last7Days' | 'last30Days' = 'last24Hours'
    private lastSubscriptions = []

    stampDocument = ''

    $refs: any

    private chartOptions: any = {
        tooltip: {
            trigger: 'axis',
            formatter: (payload: any) => {
                return `<span class="vs-h200">${payload[0].axisValue}</span><br/>${payload[0].marker} ${payload[0].seriesName}:  <span class="vs-h200">${this.$options?.filters?.formatNumber(payload[0].value)}</span>`
            },
            axisPointer: {
                type: 'shadow',
            },
        },
        grid: {
            left: 15,
            right: 15,
            bottom: 0,
            top: 40,
            containLabel: true,
        },
        legend: {
            selectedMode: false,
            itemGap: 30,
            data: [
                {
                    name: 'Iscrizioni',

                },
            ],
        },
        xAxis: {
            type: 'category',
            axisTick: {
                show: false,
            },
            axisLabel: {
                rotate: 25,
                margin: 15,
            },
            nameTextStyle: {
                color: '#60686A',
            },
            data: [],
        },
        yAxis: [
            {
                type: 'value',
                position: 'left',
                min: 0,
                // max: this.yAxisRoundTo,
                // interval: this.yAxisRoundTo / this.yAxisNumberOfItem,
                nameTextStyle: {
                    color: '#60686A',
                },
                axisLabel: {
                    formatter: (value: number) => {
                        return this.$options?.filters?.formatNumber(value)
                    },
                },
            },
        ],
        series: [
            {
                name: 'Iscrizioni',
                data: [],
                type: 'line',
                smooth: true,
                showBackground: false,
                areaStyle: {
                    color: 'rgba(208, 52, 73, 0.3)',
                },
                itemStyle: {
                    borderWidth: 1,
                    borderColor: 'rgba(208, 52, 73)',
                    color: 'rgba(208, 52, 73)',
                },
                lineStyle: {
                    color: 'rgba(208, 52, 73)',
                },
                backgroundStyle: {
                    color: 'rgba(208, 52, 73, 0.2)',
                },
            },
        ],
    }

    get formatDateFromTimeSelected () {
        switch (this.subscriptionTime) {
            case 'last24Hours': return 'HH:mm'
            default: return 'DD MMM YYYY'
        }
    }

    get user () {
        return UserModule.user
    }

    get canRepotExport () {
        return this.user.configuration.rules.reportExport
    }

    public async openModal () {
        this.open = true
        this.loading = true
        await this.getSubscriptionTrends()
        await this.getListContacts()
        this.loading = false
    }

    public closeModal () {
        this.open = false
    }

    @Watch('subscriptionTime', { immediate: false })
    async getSubscriptionTrends () {
        try {
            const resp = await getListTrendSubscription(this.list.id, { period: this.subscriptionTime })
            this.chartOptions.xAxis.data = resp.data.data.map((el: any) => formatUnixDate(el.timestamp, this.formatDateFromTimeSelected))
            this.chartOptions.series[0].data = resp.data.data.map((el: any) => el.value)
        } catch (e) {
            console.log(e)
        }
    }

    private async getListContacts () {
        try {
            this.loading = true
            const resp = await getListContacts(this.list.id, {
                orderBy: 'subscription_date;subscription_time',
                sortedBy: 'desc',
                limit: 5,
                searchFields: 'subscription_status:in',
                search: 'subscription_status:Subscribed',
                searchJoin: 'and',
            })
            this.lastSubscriptions = resp.data.data
        } catch (e) {
            console.log(e)
            this.lastSubscriptions = []
        }
        this.loading = false
    }

    async downloadPdf () {
        this.loading = true
        this.$refs.printContent.$el.style.width = '800px'
        this.$refs.chart.chartResizeHandler()
        const opt = {
            filename: `${this.list?.name}_acquisition.pdf`,
            image: { type: 'jpeg', quality: 1 },
            html2canvas: {
                windowWidth: 800,
            },
            enableLinks: false,
            jsPDF: {
                unit: 'mm',
                format: 'a4',
                orientation: 'portrait',
                hotfixes: ['px_scaling'],
            },
        }
        setTimeout(async () => {
            await html2pdf().from(this.$refs.printContent.$el).set(opt).save()
            this.$refs.printContent.$el.style.width = '100%'
            this.$refs.chart.chartResizeHandler()
            this.loading = false
        }, 1000)
    }
}
