






































































































































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import VsSeparator from '@/components/VsSeparator/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { get } from 'lodash'
import { AppModule } from '@/store/modules/app'
import axios from 'axios'
import { getListContactsStatistics, getListEngagementStatistics, getListSpamStatistics } from '@/api/consoleApi/reports'
import moment from 'moment'
import { UserModule } from '@/store/modules/user'
import html2pdf from 'html2pdf.js'
import {
    CustomFieldTypeEnum,
    CustomField,
} from '@/utils/customFields'
import { deleteList, getList, getListCustomFields, updateList } from '@/api/consoleApi/recipients'
import VsListNameModal from '@/modules/lists/components/VsListNameModal/Index.vue'
import VsListStatisticsFullAcquisition from '@/modules/lists/components/VsListStatisticsFullAcquisition/Index.vue'
import VsListStatisticsFullEngagement from '@/modules/lists/components/VsListStatisticsFullEngagement/Index.vue'
import VsListStatisticsFullQuality from '@/modules/lists/components/VsListStatisticsFullQuality/Index.vue'
import VsCloneListModal from './../../components/VsCloneListModal/Index.vue'
import { getCampaign } from '@/api/consoleApi/campaigns'

@Component({
    name: 'VsListStatistics',
    components: {
        VsContainer,
        VsSectionHeader,
        VsLoader,
        VsListNameModal,
        VsConfirm,
        VsDropdownButton,
        VsCloneListModal,
        VsSeparator,
        VsListStatisticsFullAcquisition,
        VsListStatisticsFullEngagement,
        VsListStatisticsFullQuality,
    },
})
export default class extends Vue {
    private loading = false
    private list: any = null
    campaignExist = false
    private customFields: CustomField[] = []
    private engagement: {
        campaign_sent: {
            total: number
            last: {
                timestamp: null | number
                id: null | number
            }
        }
        sent: {
            total: number
        }
        open: {
            unique: number
            total: number
            last: null | number
            or_percentage: number
        }
        click: {
            unique: number
            total: number
            last: null | number
            ctr_percentage: number
        }
    } | null = null

    private spam: {
        spam: {
            total: number
            last: null | number
        }
        suppression: {
            total: number
            last: null | number
        }
    } | null = null

    private contact: {
        contact: {
            subscribed: {
                total: number
                total_current_month: number
                last: null | number
            }
            unsubscribed: {
                total: number
                total_current_month: number
                last: null | number
            }
            opt_in_pending: {
                total: number
                total_current_month: number
                last: null | number
            }
            total: number
        }
        bounce: {
            hard: number
            soft: number
            total: number
        }
        message: {
            estimate_email: number
            estimate_sms: number
        }
    } | null = null

    $refs: any

    get is4Dem () {
        return get(AppModule.consoleConf, 'is4Dem', false)
    }

    get listId () {
        return this.$route?.params?.listId
    }

    get hasEmail () {
        return this.customFields.find(el => el.type === CustomFieldTypeEnum.email)
    }

    get hasSms () {
        return this.customFields.find(el => el.type === CustomFieldTypeEnum.mobile)
    }

    get canRepotExport () {
        return this.user.configuration.rules.reportExport
    }

    get isDeleteDisabled () {
        if (!this.list) return false
        if (this.list.last_campaign_sent_timestamp) {
            return moment().diff(moment.unix(this.list.last_campaign_sent_timestamp), 'days') <= 10
        }
        return false
    }

    get user () {
        return UserModule.user
    }

    get hasFullStatistics () {
        return this.user.configuration.rules.listStatisticFull
    }

    get showLastCampaignBadge () {
        if (!this.list) return false
        return this.list.last_campaign_sent_timestamp || (moment().diff(moment(this.list.created_at), 'days') > 30)
    }

    get lastCampaignSent () {
        const date = this.list.last_campaign_sent_timestamp
        switch (true) {
            case date === null || moment().diff(moment.unix(date), 'days') > 30:
                return {
                    type: 'warning',
                    title: this.$t('lists.listCard.lastCampaignSent.warning.title'),
                    description: this.$t('lists.listCard.lastCampaignSent.warning.description'),
                }
            case moment().diff(moment.unix(date), 'days') <= 10:
                return {
                    type: 'success',
                    title: this.$t('lists.listCard.lastCampaignSent.success.title'),
                    description: this.$t('lists.listCard.lastCampaignSent.success.description'),
                }
            case moment().diff(moment.unix(date), 'days') > 10 && moment().diff(moment.unix(date), 'days') <= 30:
                return {
                    type: 'info',
                    title: this.$t('lists.listCard.lastCampaignSent.info.title'),
                    description: this.$t('lists.listCard.lastCampaignSent.info.description'),
                }
            default:
                return {
                    type: 'info',
                    title: '',
                    description: '',
                }
        }
    }

    async beforeMount () {
        this.getList()
        this.getCustomFields()
        this.getListStatistics()
    }

    @Watch('engagement.campaign_sent.last.id', { immediate: false, deep: true })
    async getCampaign (val: string) {
        try {
            await getCampaign(val)
            this.campaignExist = true
        } catch (e) {
            console.log(e)
            this.campaignExist = false
        }
    }

    @Watch('listId', { immediate: false })
    private async getListStatistics () {
        try {
            const resp = await getListContactsStatistics(this.listId)
            this.contact = resp.data.data
            const res = await getListSpamStatistics(this.listId)
            this.spam = res.data.data
            const re = await getListEngagementStatistics(this.listId)
            this.engagement = re.data.data
        } catch (e) {
            console.log(e)
        }
    }

    @Watch('listId', { immediate: false })
    private async getCustomFields () {
        const resp = await getListCustomFields(this.listId, {
            searchFields: 'type:in',
            search: `type:${[CustomFieldTypeEnum.email, CustomFieldTypeEnum.mobile]}`,
            searchJoin: 'or',
        })
        this.customFields = resp.data.data
    }

    @Watch('listId', { immediate: false })
    private async getList () {
        try {
            const resp = await getList(this.listId)
            this.list = resp.data.data
            delete this.list.integration
        } catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 404) {
                    this.$root.$vsToast({
                        timeout: 3000,
                        heading: 'Lista non trovata',
                        aspect: VsToastAspectEnum.alert,
                    })
                    this.$router.replace({ name: 'listsIndex' })
                    return
                }
            }
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Errore nel reperire i dati della lista, riprova più tardi',
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
    }

    private async editListName () {
        try {
            const listName = await this.$refs.listNameModal.open(this.list.name)
            try {
                await this.submit(listName)
            } catch (e) {
                return
            }
        } catch (e) {
            console.log(e)
        }
        this.$refs.listNameModal.close()
    }

    async submit (listName: string) {
        try {
            this.list.name = listName
            this.list.email_address_notify = this.list.email_address_notify || ''
            await updateList(this.listId, this.list)
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Nome della lista modificato con successo',
                aspect: VsToastAspectEnum.success,
            })
        } catch (e) {
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Errore durante il salvataggio del nome della lista',
                aspect: VsToastAspectEnum.alert,
            })
        }
    }

    private async deleteList () {
        try {
            await this.$refs.deleteListConfirm.openConfirm()
        } catch (e) {
            return
        }
        this.loading = true
        try {
            const resp = await deleteList(this.listId)

            const errorCode = get(resp, 'data.data.motivation.code', '')
            if (errorCode === 'CannotDeleteRecipientUsedInRecentCampaign') {
                this.$root.$vsToast({
                    heading: `Errore durante la cancellazione della lista`,
                    message: 'Non è possibile cancellare liste che sono state usate per inviare campagne negli ultimi 10 giorni.',
                    timeout: 3000,
                    aspect: VsToastAspectEnum.alert,
                })
                this.loading = false
                return
            }

            this.$root.$vsToast({
                heading: this.$tc('lists.index.deleteSuccess', 1),
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            this.$router.replace({
                name: 'lists',
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: this.$tc('lists.index.deleteError', 1),
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
        this.loading = false
    }

    private openBlockedFunctionality (e: any, isBlocked: boolean) {
        if (isBlocked) {
            e.preventDefault()
            this.$root.$vsOpenBlockedModal()
        }
    }

    async downloadPdf () {
        const opt = {
            filename: `${this.list?.name}_overview.pdf`,
            image: { type: 'jpeg', quality: 1 },
            html2canvas: {
                // foreignObjectRendering: true,
                // scale: 1,
                windowWidth: 800,
            },
            enableLinks: false,
            jsPDF: {
                unit: 'mm',
                format: 'a4',
                orientation: 'portrait',
                // hotfixes: 'px_scaling',
            },
        }
        await html2pdf().from(this.$parent.$el).set(opt).save()
    }
}
