
























































































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsFullTopBarLayout from '@/components/VsFullTopBarLayout/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import html2pdf from 'html2pdf.js'
import {
    getListDomainsTotalClick,
    getListDomainsTotalOpen,
    getListLinks,
    getListTrendUniqueClick,
    getListTrendUniqueOpen,
} from '@/api/consoleApi/reports'
import VsChart from '@/components/VsChart/Index.vue'
import { formatUnixDate } from '@/utils/formatter'
import VsTable from '@/components/VsTable/Index.vue'
import { UserModule } from '@/store/modules/user'
import VsSeparator from '@/components/VsSeparator/Index.vue'
import moment from 'moment'
import { downloadCsv } from '@/utils/export'

@Component({
    name: 'VsListStatisticsFullEngagement',
    components: {
        VsContainer,
        VsFullTopBarLayout,
        VsChart,
        VsLoader,
        VsSectionHeader,
        VsTable,
        VsSeparator,
    },
})
export default class extends Vue {
    @Prop({ default: null, required: true }) statistics!: any
    @Prop({ default: null, required: true }) contactStatistics!: any
    @Prop({ default: null, required: true }) list!: any
    private open = false
    private loading = false
    private openClickTime: 'last24Hours' | 'last7Days' | 'last30Days' = 'last24Hours'
    private domainEvent: 'open' | 'click' = 'open'
    private domainData = []
    private totalDomain = 0
    private paginationDomain = {
        limit: 5,
        page: 1,
    }

    private linkData = []
    private totalLink = 0
    private paginationLink = {
        limit: 5,
        page: 1,
    }

    stampDocument = ''

    $refs: any

    private chartOptions: any = {
        tooltip: {
            trigger: 'axis',
            formatter: (payload: any) => {
                return `<span class="vs-h200">${payload[0].axisValue}</span><br/>${payload[0].marker} ${payload[0].seriesName}:  <span class="vs-h200">${this.$options?.filters?.formatNumber(payload[0].value)}</span> <br/>${payload[1].marker} ${payload[1].seriesName}:  <span class="vs-h200">${this.$options?.filters?.formatNumber(payload[1].value)}</span>`
            },
            axisPointer: {
                type: 'shadow',
            },
        },
        grid: {
            left: 15,
            right: 15,
            bottom: 0,
            top: 40,
            containLabel: true,
        },
        legend: {
            selectedMode: false,
            itemGap: 30,
            data: [
                {
                    name: 'Aperture uniche',
                },
                {
                    name: 'Click unici',
                },
            ],
        },
        xAxis: {
            type: 'category',
            axisTick: {
                show: false,
            },
            axisLabel: {
                rotate: 25,
                margin: 15,
            },
            nameTextStyle: {
                color: '#60686A',
            },
            data: [],
        },
        yAxis: [
            {
                type: 'value',
                position: 'left',
                min: 0,
                // max: this.yAxisRoundTo,
                // interval: this.yAxisRoundTo / this.yAxisNumberOfItem,
                nameTextStyle: {
                    color: '#60686A',
                },
                axisLabel: {
                    formatter: (value: number) => {
                        return this.$options?.filters?.formatNumber(value)
                    },
                },
            },
        ],
        series: [
            {
                name: 'Aperture uniche',
                data: [],
                type: 'line',
                smooth: true,
                showBackground: false,
                areaStyle: {
                    color: 'rgba(208, 52, 73, 0.3)',
                },
                itemStyle: {
                    borderWidth: 1,
                    borderColor: 'rgba(208, 52, 73)',
                    color: 'rgba(208, 52, 73)',
                },
                lineStyle: {
                    color: 'rgba(208, 52, 73)',
                },
                backgroundStyle: {
                    color: 'rgba(208, 52, 73, 0.2)',
                },
            },
            {
                name: 'Click unici',
                data: [],
                type: 'line',
                smooth: true,
                showBackground: false,
                areaStyle: {
                    color: 'rgba(64, 158, 220, 0.3)',
                },
                itemStyle: {
                    borderWidth: 1,
                    borderColor: 'rgba(64, 158, 220)',
                    color: 'rgba(64, 158, 220)',
                },
                lineStyle: {
                    color: 'rgba(64, 158, 220)',
                },
                backgroundStyle: {
                    color: 'rgba(64, 158, 220, 0.2)',
                },
            },
        ],
    }

    get formatDateFromTimeSelected () {
        switch (this.openClickTime) {
            case 'last24Hours': return 'HH:mm'
            default: return 'DD MMM YYYY'
        }
    }

    get user () {
        return UserModule.user
    }

    get canRepotExport () {
        return this.user.configuration.rules.reportExport
    }

    get lastCampaignSent () {
        const date = this.list.last_campaign_sent_timestamp
        switch (true) {
            case date === null || moment().diff(moment.unix(date), 'days') > 30:
                return {
                    type: 'warning',
                    title: this.$t('lists.listCard.lastCampaignSent.warning.title'),
                    description: this.$t('lists.listCard.lastCampaignSent.warning.description'),
                }
            case moment().diff(moment.unix(date), 'days') <= 10:
                return {
                    type: 'success',
                    title: this.$t('lists.listCard.lastCampaignSent.success.title'),
                    description: this.$t('lists.listCard.lastCampaignSent.success.description'),
                }
            case moment().diff(moment.unix(date), 'days') > 10 && moment().diff(moment.unix(date), 'days') <= 30:
                return {
                    type: 'info',
                    title: this.$t('lists.listCard.lastCampaignSent.info.title'),
                    description: this.$t('lists.listCard.lastCampaignSent.info.description'),
                }
            default:
                return {
                    type: 'info',
                    title: '',
                    description: '',
                }
        }
    }

    get lastOpenRate () {
        const date = this.statistics.open.last
        switch (true) {
            case date === null || moment().diff(moment.unix(date), 'days') > 15:
                return {
                    type: 'warning',
                    title: this.$t('lists.listCard.lastEngagementRate.warning.title'),
                }
            case moment().diff(moment.unix(date), 'days') <= 15:
                return {
                    type: 'success',
                    title: this.$t('lists.listCard.lastEngagementRate.success.title'),
                }
            default:
                return {
                    type: 'info',
                    title: '',
                    description: '',
                }
        }
    }

    get lastClickRate () {
        const date = this.statistics.click.last
        switch (true) {
            case date === null || moment().diff(moment.unix(date), 'days') > 15:
                return {
                    type: 'warning',
                    title: this.$t('lists.listCard.lastEngagementRate.warning.title'),
                }
            case moment().diff(moment.unix(date), 'days') <= 15:
                return {
                    type: 'success',
                    title: this.$t('lists.listCard.lastEngagementRate.success.title'),
                }
            default:
                return {
                    type: 'info',
                    title: '',
                    description: '',
                }
        }
    }

    public async openModal () {
        this.open = true
        this.loading = true
        await this.getClickOpenTrends()
        await this.getDomainTableData()
        await this.getCampaignLinks()
        this.loading = false
    }

    public closeModal () {
        this.open = false
    }

    @Watch('openClickTime', { immediate: false })
    async getClickOpenTrends () {
        try {
            const resp = await getListTrendUniqueOpen(this.list.id, { period: this.openClickTime })
            this.chartOptions.xAxis.data = resp.data.data.map((el: any) => formatUnixDate(el.timestamp, this.formatDateFromTimeSelected))
            this.chartOptions.series[0].data = resp.data.data.map((el: any) => el.value)
        } catch (e) {
            console.log(e)
        }
        try {
            const res = await getListTrendUniqueClick(this.list.id, { period: this.openClickTime })
            this.chartOptions.series[1].data = res.data.data.map((el: any) => el.value)
        } catch (e) {
            console.log(e)
        }
    }

    async downloadPdf () {
        this.loading = true
        this.$refs.printContent.$el.style.width = '800px'
        this.$refs.chart.chartResizeHandler()
        const opt = {
            filename: `${this.list?.name}_acquisition.pdf`,
            image: { type: 'jpeg', quality: 1 },
            html2canvas: {
                windowWidth: 800,
            },
            enableLinks: false,
            jsPDF: {
                unit: 'mm',
                format: 'a4',
                orientation: 'portrait',
                hotfixes: ['px_scaling'],
            },
        }
        setTimeout(async () => {
            await html2pdf().from(this.$refs.printContent.$el).set(opt).save()
            this.$refs.printContent.$el.style.width = '100%'
            this.$refs.chart.chartResizeHandler()
            this.loading = false
        }, 1000)
    }

    async exportDomain () {
        this.loading = true
        const domains: any[] = []

        await this.loopCall(1, domains, this.domainEvent === 'open' ? getListDomainsTotalOpen : getListDomainsTotalClick)
        const csvArray: any[] = [['Dominio', this.domainEvent === 'open' ? 'Aperture totali' : 'Click totali']]
        domains.forEach(domain => {
            csvArray.push([domain.domain, domain.value])
        })
        const csv = csvArray.map(row => row.join(',')).join('\n')
        downloadCsv(csv, this.domainEvent === 'open' ? `${this.list?.name}_engagement_per_dominio_aperture` : `${this.list?.name}_engagement_per_dominio_click`)
        this.loading = false
    }

    async loopCall (page: number, values: any, caller: any) {
        const resp = await caller(this.list.id, {
            page,
            limit: 1000,
        })
        values.push(...resp.data.data)
        if (resp?.data?.meta?.pagination?.current_page < resp?.data?.meta?.pagination?.total_pages) {
            await this.loopCall(resp.data.meta.pagination.current_page + 1, values, caller)
        }
    }

    @Watch('domainEvent', { immediate: false })
    @Watch('paginationDomain', { immediate: false, deep: true })
    async getDomainTableData () {
        try {
            if (this.domainEvent === 'open') {
                const resp = await getListDomainsTotalOpen(this.list.id, this.paginationDomain)
                if (resp.data.data.length === 0 && this.paginationDomain.page > 1) {
                    this.paginationDomain.page = 1
                }
                this.domainData = resp.data.data
                this.totalDomain = resp.data.meta.pagination.total
            } else {
                const resp = await getListDomainsTotalClick(this.list.id, this.paginationDomain)
                if (resp.data.data.length === 0 && this.paginationDomain.page > 1) {
                    this.paginationDomain.page = 1
                }
                this.domainData = resp.data.data
                this.totalDomain = resp.data.meta.pagination.total
            }
        } catch (e) {
            console.log(e)
            this.domainData = []
            this.totalDomain = 0
        }
    }

    @Watch('paginationLink', { immediate: false, deep: true })
    async getCampaignLinks () {
        try {
            const resp = await getListLinks(this.list.id, this.paginationLink)
            if (resp.data.data.length === 0 && this.paginationLink.page > 1) {
                this.paginationLink.page = 1
            }
            this.linkData = resp.data.data
            this.totalLink = resp.data.meta.pagination.total
        } catch (e) {
            console.log(e)
            this.linkData = []
            this.totalLink = 0
        }
    }

    async exportLink () {
        this.loading = true
        const links: any[] = []

        await this.loopCall(1, links, getListLinks)
        const csvArray: any[] = [['Link', 'Tag comportamentali', 'Click totali']]
        links.forEach(link => {
            csvArray.push([link.link, link.tag, link.total_click])
        })
        const csv = csvArray.map(row => row.join(',')).join('\n')
        downloadCsv(csv, `${this.list?.name}_engagement_sui_link`)
        this.loading = false
    }
}
