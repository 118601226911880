










































import { Component, Prop, Vue } from 'vue-property-decorator'
import {
    duplicateList,
} from '@/api/consoleApi/recipients'
import { get } from 'lodash'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'

@Component({
    name: 'VsCloneListModal',
})
export default class extends Vue {
    @Prop({
        default: () => [],
        required: false,
    }) tags!: any[]

    private show = false
    private loading = false
    private listId = ''
    private name = ''
    $refs: any

    private async cloneList () {
        this.loading = true
        const data = {
            name: this.name,
        }
        try {
            await duplicateList(this.listId, data)
            this.$root.$vsToast({
                heading: this.$t('lists.cloneListModal.success'),
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            this.$emit('list-cloned')
            this.show = false
        } catch (e) {
            const message = get(e, 'response.data.Error', this.$t('lists.cloneListModal.error'))
            this.$root.$vsToast({
                heading: message,
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
        }
        this.loading = false
    }

    public openModal (listId: any) {
        this.listId = listId
        this.name = ''
        this.show = true
    }
}
